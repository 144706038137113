












































import Vue from 'vue';
import { constructDownloadPage } from '@/api/export';
import { ExportRecordsType } from '@/interface/product';
const columns = [
  {
    title: '序号',
    dataIndex: 'disNo',
    align: 'center',
    customRender: (text, record, index) => index + 1,
  },
  {
    title: '清单标题',
    align: 'center',
    dataIndex: 'constructProjectName',
  },
  {
    title: 'MD5',
    align: 'center',
    dataIndex: 'constructProjectMd5',
  },
  {
    title: '清单类型',
    dataIndex: 'fileType',
    align: 'center',
    customRender: (text) => {
      const map = {
        1: 'EXL、PDF',
        2: 'XML',
      };
      return map[text] || '--';
    },
  },
  {
    title: 'IP',
    align: 'center',
    dataIndex: 'ip',
  },
  {
    title: '导出来源',
    dataIndex: 'exportSource',
    align: 'center',
    customRender: (text) => {
      const map = {
        0: '本平台',
        1: '招标通',
      };
      return map[text] || '--';
    },
  },
  {
    title: '导出时间',
    align: 'center',
    dataIndex: 'exportTime',
  },
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   align: 'center',
  //   scopedSlots: { customRender: 'action' },
  // },
];
export default Vue.extend({
  name: 'exportList',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ReportForms: () =>
      import('@/views/projects/detail/engineeringProject/reportForms.vue'),
  },
  watch: {
    visible: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.getExportData();
      }
    },
  },
  data() {
    return {
      title: '导出记录',
      columns: Object.freeze(columns),
      exportData: [] as ExportRecordsType[],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formVisible: false,
    };
  },
  methods: {
    getExportData() {
      const { current, pageSize } = this.pagination;
      const params = {
        page: current,
        limit: pageSize,
      };
      constructDownloadPage(params).then(({ code, result }) => {
        const { total, list } = result;
        this.exportData = list.map((item, index) => {
          return { ...item, key: index };
        });
        this.pagination.total = total;
      });
    },
    close() {
      this.$emit('update:visible', false);
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getExportData();
    },
    viewForm(record) {
      const exportReportUrl = JSON.parse(record.exportReportUrl);
      console.log(exportReportUrl);
      const reportFormData = exportReportUrl?.children;
      if (!reportFormData) return;
      this.formVisible = true;
      this.$nextTick(() => {
        (this.$refs.reportForm as any).getReportFormTree(reportFormData);
      });
    },
  },
});
