import http from '@/utils/http';
import request from '@/utils/request';
import { ExportApproveStatus, ExportRecordsType } from '@/interface/product';
import { EditPdfSaveType, EditPdfOptionType } from '@/interface/pdfEdit';

/**
 * 审批导出次数接口
 * @param sequenceNbr 项目空间id
 * @returns
 */
export function exportApprove(sequenceNbr: string) {
  return http.post<string>('/cocc-mtds-resource/1/projectSpace/exportApprove', {
    sequenceNbr,
  });
}

/**
 * 查询审批结果接口
 * @param sequenceNbr
 * @returns
 */
export function getExportApproveResult(sequenceNbr: string) {
  return http.get<ExportApproveStatus>(
    `/cocc-mtds-resource/1/projectSpace/getExportApproveResult/${sequenceNbr}`
  );
}

/**
 * 查询平台导出权益次数
 * @param sequenceNbr
 * @returns
 */
export function getExportTimes(sequenceNbr: string) {
  return http.get<number>(
    `/cocc-mtds-resource/1/projectSpace/getExportTimes/${sequenceNbr}`
  );
}

/**
 * 终止导出
 * @param sequenceNbr
 * @returns
 */
export function exportCancel(sequenceNbr: string) {
  return http.get<number>(
    `/cocc-mtds-resource/1/project/constructs/${sequenceNbr}/export/cancel`
  );
}

/**
 * 下载进度条展示
 * @param sequenceNbr
 * @returns
 */
export function downloadProcess(sequenceNbr: string) {
  return http.get<number>(
    `/cocc-mtds-resource/1/projectView/downloadProcess/${sequenceNbr}`
  );
}

/**
 * 导出 初始化
 * @param sequenceNbr
 * @returns
 */
export function exportInit(sequenceNbr: string) {
  return http.get<number>(
    `/cocc-mtds-resource/1/projectView/exportInit/${sequenceNbr}`
  );
}

/** 默认可操作显示项
 * @param constructId
 * @returns
 */
export function editorOptions(constructId: string) {
  return http.get<EditPdfOptionType>(
    `/cocc-mtds-resource/1/construct/editor/${constructId}/show/options`
  );
}

/**
 * 保存pdf编辑
 * @param params
 * @returns
 */
export function editPdfSave(params: EditPdfSaveType) {
  return http.post<boolean>(
    '/cocc-mtds-resource/1/construct/editor/edit',
    params
  );
}
/**
 * 导出 初始化
 * @param sequenceNbr 投标ID
 * @returns
 */
export function exportConstructDetailUrl(sequenceNbr: string, format: string) {
  return http.get<number>(
    `/cocc-mtds-resource/1/project/exportConstruct/${sequenceNbr}/${format}/biddetail`
  );
}

/**
 * 导出时横竖版合成投标清单
 * @returns
 */
export function constructCompositeBidDetail(params) {
  return request({
    url: `/cocc-mtds-resource/1/projectView/constructComposite/biddetail`,
    data: params,
    method: 'post',
  });
}

/**
 * 导出记录
 * @param params
 * @returns
 */
export function constructDownloadPage(params) {
  return http.get<{ total: number; list: ExportRecordsType[] }>(
    'cocc-mtds-resource/1/project/constructDownloadPage',
    params
  );
}
